import { Datum } from "../handlers/opcData";

export interface DowntimeReport {
  downtimeEvents: number;
  // Total downtime in milliseconds
  totalDowntime: number;
  // Total time in milliseconds
  totalTime: number;
  // Uptime percentage
  uptimePercentage: number;
  // Downtime percentage
  downtimePercentage: number;
}

interface DowntimeArgs {
  threshold: number;
}

export function downtime(data: Datum[] | undefined = undefined, { threshold }: DowntimeArgs): DowntimeReport {
  if (!data) {
    return {
      downtimeEvents: 0,
      totalDowntime: 0,
      totalTime: 0,
      uptimePercentage: 100,
      downtimePercentage: 0,
    };
  }
  if (data.length === 0) {
    return {
      downtimeEvents: 0,
      totalDowntime: 0,
      totalTime: 0,
      uptimePercentage: 100,
      downtimePercentage: 0,
    };
  }
  const startTime = data[0].timeUnix;
  const endTime = data[data.length - 1].timeUnix;
  const totalDuration = endTime - startTime;
  let lastTime = startTime;
  let downtimeEvents = 0;
  let totalDowntime = 0;
  let downtime = false;
  for (const datum of data.slice(1)) {
    const dt = datum.timeUnix - lastTime;
    if ((datum.value as number) >= threshold) {
      downtime = false;
      lastTime = datum.timeUnix;
      continue;
    }
    if (!downtime) {
      downtimeEvents++;
      downtime = true;
    }
    totalDowntime += dt;
    lastTime = datum.timeUnix;
  }
  const uptime = totalDuration - totalDowntime;
  return {
    downtimeEvents,
    totalDowntime,
    totalTime: totalDuration,
    uptimePercentage: (uptime / totalDuration) * 100,
    downtimePercentage: (totalDowntime / totalDuration) * 100,
  };
}
