import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../ui/card";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { AlertTriangle, Clock, ArrowUpCircle } from "lucide-react";
import { DowntimeReport } from "../../lib/downtime";

interface DowntimeDisplayProps {
  timeframe: string;
  threshold: number
  report: DowntimeReport;
}

export function DowntimeDisplay({ report, threshold, timeframe }: DowntimeDisplayProps) {
  const formatDuration = (ms: number) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days}d ${hours % 24}h`;
    if (hours > 0) return `${hours}h ${minutes % 60}m`;
    if (minutes > 0) return `${minutes}m ${seconds % 60}s`;
    return `${seconds}s`;
  };

  const chartData = [
    { name: "Uptime", value: report.uptimePercentage },
    { name: "Downtime", value: report.downtimePercentage },
  ];

  const colors = ["#10B981", "#EF4444"];

  return (
    <Card className="w-full max-w-md">
      <CardHeader>
        <CardTitle className="text-lg font-semibold">Slicer Downtime Summary</CardTitle>
        <CardDescription className="text-sm text-muted-foreground">Events where slice rate is below {threshold} tons/hr.</CardDescription>
        <p className="text-sm text-muted-foreground">{timeframe}</p>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="flex items-center space-x-2">
            <AlertTriangle className="h-5 w-5 text-yellow-500" />
            <div>
              <p className="text-sm font-medium">Downtime Events</p>
              <p className="text-2xl font-bold">{report.downtimeEvents}</p>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <Clock className="h-5 w-5 text-blue-500" />
            <div>
              <p className="text-sm font-medium">Total Downtime</p>
              <p className="text-2xl font-bold">{formatDuration(report.totalDowntime)}</p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center space-x-2 mb-4">
          <ArrowUpCircle className="h-5 w-5 text-green-500" />
          <p className="text-sm font-medium">Uptime</p>
          <p className="text-2xl font-bold">{report.uptimePercentage.toFixed(2)}%</p>
        </div>
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              paddingAngle={5}
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>
            <Tooltip
              formatter={(value: number) => `${value.toFixed(2)}%`}
              contentStyle={{ background: "rgba(0, 0, 0, 0.8)", border: "none" }}
              itemStyle={{ color: "#fff" }}
            />
          </PieChart>
        </ResponsiveContainer>
        <div className="flex justify-center mt-2 space-x-4">
          {chartData.map((entry, index) => (
            <div key={entry.name} className="flex items-center">
              <div className="w-3 h-3 mr-1" style={{ backgroundColor: colors[index] }}></div>
              <span className="text-sm">{entry.name}</span>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
