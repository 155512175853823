import { downtime } from "lib/downtime";
import { useDashboardData } from "../../context/dashboard";
import { useEffect, useMemo, useState } from "react";
import { DowntimeDisplay } from "./downtime";

export interface DowntimeWidgetProps {
  threshold: number;
  reference: string;
}

export function DowntimeWidget({ threshold, reference }: DowntimeWidgetProps) {
  const { data, timeFrameMin } = useDashboardData(reference);
  const report = useMemo(() => downtime(data, { threshold }), [data, threshold]);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return <DowntimeDisplay threshold={threshold} timeframe={timeFrame(currentTime, timeFrameMin)} report={report} />;
}

function timeFrame(currentTime: Date, timeFrameMin: number): string {
  const start = new Date(currentTime.getTime() - timeFrameMin * 60 * 1000);
  return `${start.toLocaleString()} - ${currentTime.toLocaleString()}`;
}
